<template>
	<ion-page>
		<ion-header>
			<ion-toolbar class="color-bg">
				<ion-back-button :text="$isWeb.value ? 'Academy' : ''" slot="start" :defaultHref="{name: 'home'}" />
				<ion-title>
					<ion-label class="mobile-version">Academy</ion-label>
				</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			
			<W-login />

		</ion-content>
	</ion-page>
</template>

<script>
import WLogin from '@/plugins/w@screen/login/w-login.vue'

export default {
	components: {
		WLogin
	},
}
</script>

<style lang="sass" scoped>
@import '@/plugins/lib@user/custom/lib@user-form.sass'

::v-deep 
	@extend .libuser-form
</style>