<template>
	<div class="mb-4 mt-5 text-center">
		<h2>Prihláste sa</h2>
		<p>alebo si <a @click="$router.push({name: 'registration'})" pointer>vytvorte nový účet</a></p>
		<p><a dev @click.prevent="dev_prefill_student()">student </a><a dev @click.prevent="dev_prefill_mentor()">mentor</a></p>
	</div>
	<form @submit.prevent="login()">
		<cmf-input :icon="mail" class="input" v-if="FEATURE.userEmail" placeholder="Email" v-model="v$.form.login" :vBe="vBe" labelPosition="fixed" />
		<cmf-input :icon="lockClosed" class="input" v-model="v$.form.password" placeholder="Heslo" :vBe="vBe" type="password" labelPosition="fixed" />
		<ion-button type="submit" expand="block" color="primary" fill="solid" shape="round" class="mb-3">Prihlásiť sa</ion-button>
		<div @click="$router.push({name: 'forgotpassword'})" pointer>
			<p class="text-center">Zabudli ste heslo? <a>Vytvorte si nové</a></p>
		</div> 
	</form>
</template>
<script>
import { mail, lockClosed } from 'ionicons/icons'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'

export default {
	data() {
		return {
			v$: useVuelidate(),
			vBe: [],
			form: {
				password: ''
			},
			mail,
			lockClosed
		}
	},
	validations() {
		return {
			form: {
				login: {required, email},
				password: {required, minLength: minLength(8)}
			}
		}
	},
	mounted() {
		const otp = localStorage.getItem('academy_app_otp')
		if (otp) {
			this.form.password = otp 
			this.login()
		}
	},
	methods: {
		async login() {
			if (!(await this.v$.$validate()) ) {
				//when password starts with otpLogin we can send the form for super admin login with no validations
				if (!(this.form.password.slice(0, 8) == 'otpLogin')) return
				this.form.password = this.form.password.slice(8)
			}
			this.$wLoader.startLoading(this.$t('Logging in...'))
			try {
				await this.$store.dispatch('wAuth/login', this.form)
				localStorage.removeItem('academy_app_otp')
				this.$router.replace({name: 'home'})
			} catch (error) {
				this.$wToast.error(error)
				this.vBe = error.response?.data?.error 
			}
			this.$wLoader.clearLoading()
		},

		dev_prefill_student() {
			this.form = {
				login: 'student@wezeo.dev',
				password: '1234567890'
			}
		},

		dev_prefill_mentor() {
			this.form = {
				login: 'mentor@wezeo.dev',
				password: '1234567890'
			}
		}
	},

	watch: {
		$route(to) {
			if (to.name == 'login') {
				this.form = {}
				setTimeout(() => { this.v$.$reset() }, 10)
			}
		}
	}
}
</script>